import config from '@/config/env-constants';
import _ from 'lodash';

function getDefaultAssetTypeObj() {
    return {
        origin: '',
        originId: '',
        brand: '',
        type: null,
        name: '',
        description: '',
        isRental: false,
        rentFrequency: 'Per Day',
        rentRate: 0,
        rentCurrency: 'PHP',
        isActive: 'true',
        isPublic: false,
        inventoryDetails: getDefaultInventoryDetails(),
        conditions: getDefaultConditions(),
        dateCreated: '',
        createdBy: '',
        dateUpdated: '',
        updatedBy: '',
    };
}

function getDefaultConditions() {
    return [
        {
            id: 'good',
            condition: 'Good',
            description: "Default",
            isBillable: false,
            isActive: true
        },
        {
            id: 'damaged',
            condition: 'Damaged',
            description: "Default",
            isBillable: true,
            isActive: true
        },
        {
            id: 'ber',
            condition: 'BER',
            description: "Default",
            isBillable: false,
            isActive: false
        }
    ];
}

function getDefaultInventoryDetails() {
    return [
        {
            id: 'uom',
            fieldName: 'Unit of Measure (UOM)',
            defaultValue: 'PC',
            isRequired: true,
            isActive: true,
        },
        {
            id: 'unitCost',
            fieldName: 'Unit Cost (PHP)',
            defaultValue: '0',
            isRequired: true,
            isActive: true,
        }
    ];
}

function getDefaultHandling(conditionId) {
    let handling = null;

    if (conditionId === 'good') {
        handling = 'No Action Needed';
    } else if (conditionId === 'ber') {
        handling = 'For Disposal';
    } else if (conditionId === 'damaged') {
        handling = 'For Repair';
    }

    return handling;
}

function isDefaultCondition(condition) {
    return condition === 'good' || condition === 'ber' || condition === 'damaged';
}

function cleanupFields(assetType) {
    let cleanedObj = { ...assetType };

    delete cleanedObj['uom'];
    delete cleanedObj['unitCost'];
    delete cleanedObj['Other Inventory Details'];
    delete cleanedObj['status'];
    delete cleanedObj['Is Rental?'];
    delete cleanedObj['Is Public?'];
    delete cleanedObj['Date Created'];
    delete cleanedObj['Date Updated'];
    delete cleanedObj['_showDetails'];

    return cleanedObj;
}

function getUOMDisplay(uomCode, allUOMsObj) {
    let uomObj = allUOMsObj[uomCode];
    if (!_.isEmpty(uomObj)) {
        return uomObj.code + ' (' + uomObj.name + ')';
    }
    return uomCode;
}

function getUOMCode(assetType) {
    let uomCode = 'PC'
    if (assetType && assetType.inventoryDetails && !_.isEmpty(assetType.inventoryDetails)) {
        uomCode = assetType.inventoryDetails[0].defaultValue;
    }
    return uomCode;
}

function getUnitCost(assetType) {
    let unitCost = '0'
    if (assetType && assetType.inventoryDetails && _.size(assetType.inventoryDetails) >= 2) {
        unitCost = assetType.inventoryDetails[1].defaultValue;
    }
    return unitCost;
}

function exceedMaximumPublic(company, assetTypes, currAssetType) {
    let maxNo = config.maxInQuery;
    if (!_.isEmpty(assetTypes)) {
        let publicAssetTypes = _.filter(assetTypes, o => {
            return o.isPublic && company.id === o.originId;
        });

        let publicAssetTypeIds = _.map(publicAssetTypes, 'id');
        if (currAssetType && publicAssetTypeIds.includes(currAssetType.id)) {
            // skip if the currAssetType is already part of current public assetTypes
            return false;
        } else {
            return _.size(publicAssetTypes) >= maxNo;
        }
    }
    return false;
}

export const AssetTypeUtil = {
    getDefaultAssetTypeObj,
    getDefaultConditions,
    getDefaultInventoryDetails,
    getDefaultHandling,
    isDefaultCondition,
    cleanupFields,
    getUOMDisplay,
    getUOMCode,
    getUnitCost,
    exceedMaximumPublic
}