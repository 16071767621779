<template>
	<b-card>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
		<b-tabs>
			<b-tab title="Primary Details" active>
				<b-card-text>
					<b-row class="mb-2">
						<b-col sm="7">
							<b-form-group label="Dispatch No." label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right" description="Serialized Dispatch ID per company">
								<span class="numFont">{{ row.item.dispatchNo }}</span>
							</b-form-group>
							<b-form-group label="Dispatch ID" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right"  description="Dispatch Unique ID">
								<span class="numFont">{{ row.item.dispatchId }}</span>
							</b-form-group>
							<b-form-group label="Source" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ sourceCompany }}
								<div class="numFont mt-1">
									<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Map Location'"
										@click.stop="showDispatchLocation(row.item, 'source')" variant="dark" class="mr-1">
										<em class="fa fa-map-marker fa-lg"></em>
									</b-button>{{ getCoordinates(row.item.source.geoaddress) }}
								</div>
							</b-form-group>
							<b-form-group label="Destination" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ destinationCompany }}
								<div class="numFont mt-1">
									<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Map Location'"
										@click.stop="showDispatchLocation(row.item, 'destination')" variant="dark" class="mr-1">
										<em class="fa fa-map-marker fa-lg"></em>
									</b-button>{{ getCoordinates(row.item.destination.geoaddress) }}
								</div>
							</b-form-group>

							<b-form-group v-show="hasClientAccount(row.item)" label="Client Account" label-cols-sm="4"
								label-class="font-weight-bold pt-0" label-align-sm="right">
								{{ row.item.clientAccountNo }}
							</b-form-group>

							<b-form-group v-show="row.item.isDelayedTransfer" label="Is Delayed Transfer?" label-cols-sm="4"
								label-class="font-weight-bold pt-0" label-align-sm="right">
								<span v-if="row.item.isDelayedTransfer">
									<b-badge variant="success" class="badgeFont">YES</b-badge>
								</span>
								<span v-else>
									<b-badge variant="secondary" class="badgeFont">NO</b-badge>
								</span>
							</b-form-group>

							<b-form-group v-show="row.item.isDelayedTransfer" label="Delayed Transfer Days" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right" description="Days before accountability transfer to destination">
								<span class="numFont">{{ row.item.delayedTransferDays }} </span>day(s)
							</b-form-group>

							<b-form-group label="Remarks" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								<span class="truncate-text">
									<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100" less="Show Less"
										type="html" :text="row.item.notes" />
								</span>
							</b-form-group>

							<b-form-group label="Date Created" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ showFormattedDate(row.item.dateCreated) }}
							</b-form-group>
							<b-form-group label="Created By" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ getUserDisplay(row.item.createdBy) }}
							</b-form-group>

							<b-form-group label="Date Updated" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ showFormattedDate(row.item.dateUpdated) }}
							</b-form-group>
							<b-form-group label="Updated By" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ getUserDisplay(row.item.updatedBy) }}
							</b-form-group>

							<b-form-group v-show="row.item.status === 'Cancelled'" label="Date Cancelled" label-cols-sm="4"
								label-class="font-weight-bold pt-0" label-align-sm="right">
								{{ showFormattedDate(row.item.dateCancelled) }}
							</b-form-group>
							<b-form-group v-show="row.item.status === 'Cancelled'" label="Cancelled By" label-cols-sm="4"
								label-class="font-weight-bold pt-0" label-align-sm="right">
								{{ getUserDisplay(row.item.cancelledBy) }}
							</b-form-group>

							<b-form-group v-show="row.item.status === 'Received'" label="Date Received" label-cols-sm="4"
								label-class="font-weight-bold pt-0" label-align-sm="right">
								{{ showFormattedDate(row.item.dateReceived) }}
							</b-form-group>
							<b-form-group v-show="row.item.status === 'Received'" label="Received By" label-cols-sm="4"
								label-class="font-weight-bold pt-0" label-align-sm="right">
								{{ getUserDisplay(row.item.receivedBy) }}
							</b-form-group>

							<b-form-group v-show="row.item.status === 'Rejected'" label="Date Rejected" label-cols-sm="4"
								label-class="font-weight-bold pt-0" label-align-sm="right">
								{{ showFormattedDate(row.item.dateRejected) }}
							</b-form-group>
							<b-form-group v-show="row.item.status === 'Rejected'" label="Rejected By" label-cols-sm="4"
								label-class="font-weight-bold pt-0" label-align-sm="right">
								{{ getUserDisplay(row.item.rejectedBy) }}
							</b-form-group>
						</b-col>
					</b-row>
				</b-card-text>
			</b-tab>

			<b-tab title="Transportation">
				<b-card-text>
					<b-row class="mb-2">
						<b-col sm="7">
							<b-form-group label="Plate No./Conduction No." label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								<span class="numFont">
									{{ row.item.transportation.plateNo }}
								</span>
							</b-form-group>

							<b-form-group label="Company" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.transportation.company }}
							</b-form-group>

							<b-form-group label="Driver" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.driver.name }}
							</b-form-group>

							<b-form-group label="Assistants" label-cols-sm="4" label-class="font-weight-bold pt-0"
								label-align-sm="right">
								{{ row.item.driver.assistants ? row.item.driver.assistants : "-" }}
							</b-form-group>
						</b-col>
						<b-col sm="3" v-if="hasImage(row.item.driver.license)">
							<b-img v-img-orientation-changer :src="row.item.driver.license.url" alt="Responsive image"
								thumbnail fluid />
							<a href="#" class="pull-left" @click.prevent="showImage(row.item.driver.license.url)">
								Click to view full image
							</a>
						</b-col>
					</b-row>
				</b-card-text>
			</b-tab>

			<b-tab title="Assets">
				<b-card-text class="mt-3">
					<DispatchDetailsAssetView :row="row" :allClassifications="allClassifications" />
				</b-card-text>
			</b-tab>

			<b-tab title="Proof of Dispatch" v-if="proofOfDispatch != null">
				<b-card-text>
					<b-row class="mb-2">
						<b-col sm="2" class="text-sm-right">
							<b>Images</b>
						</b-col>
						<b-col sm="3" v-if="image !== null" v-for="(image, index) in proofOfDispatch.images" :key="index">
							<span>
								<b-img v-img-orientation-changer :src="image.url" alt="Responsive image" thumbnail
									fluid />
								<a href="#" class="pull-left" @click.prevent="showImage(image.url)">
									Click to view full image
								</a>
							</span>
						</b-col>
					</b-row>
					<b-row class="mb-2">
						<b-col sm="2" class="text-sm-right">
							<b>Dispatcher</b>
						</b-col>
						<b-col>{{ proofOfDispatch.dispatcherName }}</b-col>
					</b-row>
					<b-row class="mb-2">
						<b-col sm="2" class="text-sm-right">
							<b>Remarks</b>
						</b-col>
						<b-col sm="4">
							<div v-if="proofOfDispatch.remarks">
								<span class="truncate-text">
									<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100"
										less="Show Less" type="html" :text="proofOfDispatch.remarks" />
								</span>
							</div>
						</b-col>
					</b-row>
				</b-card-text>
			</b-tab>

			<b-tab title="Proof of Receipt" v-if="proofOfReceipt != null">
				<b-card-text>
					<b-row class="mb-2">
						<b-col sm="2" class="text-sm-right">
							<b>Images</b>
						</b-col>
						<b-col sm="3" v-if="image !== null" v-for="(image, index) in proofOfReceipt.images" :key="index">
							<span>
								<b-img v-img-orientation-changer :src="image.url" alt="Responsive image" thumbnail
									fluid />
								<a href="#" class="pull-left" @click.prevent="showImage(image.url)">
									Click to view full image
								</a>
							</span>
						</b-col>
					</b-row>
					<b-row class="mb-2">
						<b-col sm="2" class="text-sm-right">
							<b>Receiver</b>
						</b-col>
						<b-col>{{ proofOfReceipt.recipientName }}</b-col>
					</b-row>
					<b-row class="mb-2">
						<b-col sm="2" class="text-sm-right">
							<b>Remarks</b>
						</b-col>
						<b-col sm="4">
							<div v-if="proofOfReceipt.remarks">
								<span class="truncate-text">
									<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100"
										less="Show Less" type="html" :text="proofOfReceipt.remarks" />
								</span>
							</div>
						</b-col>
					</b-row>
				</b-card-text>
			</b-tab>
		</b-tabs>

		<b-row class="mt-4 mb-2">
			<b-col sm="12" class="text-sm-right">
				<b-button size="sm" @click="row.toggleDetails" variant="secondary"
					v-b-tooltip.hover.top="'Hide Details'">
					<em class="icon-arrow-up"></em>
				</b-button>
			</b-col>
		</b-row>

		<InvalidImageDialog />
	</b-card>
</template>

<script>
// Components
import InvalidImageDialog from '../common/InvalidImageDialog';
import DispatchDetailsAssetView from './DispatchDetailsAssetView';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';
import { LocationUtil } from '@/utils/locationUtil';
import { FileUtil } from '@/utils/fileUtil';
import { UserUtil } from '@/utils/userutil';

// DAO
import classificationDAO from '@/database/classifications';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';


export default {
	name: 'dispatch-details-view',
	components: {
		InvalidImageDialog,
		DispatchDetailsAssetView,
		Loading,
		truncate
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
			allClassifications: {},

			// Check for loader
			isLoading: false,
		}
	},
	computed: {
		sourceCompany() {
			return DispatchUtil.getCompanyLocationDisplay(this.row.item.source);
		},
		destinationCompany() {
			return DispatchUtil.getCompanyLocationDisplay(this.row.item.destination);
		},
		geoaddress() {
			let proofOfReceipt = this.row.item.proofOfReceipt ? this.row.item.proofOfReceipt : {};
			return proofOfReceipt.geoaddress ? proofOfReceipt.geoaddress : {};
		},
		proofOfDispatch() {
			return this.row.item.proofOfDispatch ? this.row.item.proofOfDispatch : null;
		},
		proofOfReceipt() {
			return this.row.item.proofOfReceipt ? this.row.item.proofOfReceipt : null;
		}
	},
	async mounted() {
		await this.retrieveClassification();

		EventBus.$on('onCloseSaveClassification', async () => {
			await this.retrieveClassification();
		});
	},
	methods: {
		async retrieveClassification() {
			// show loading indicator
			this.isLoading = true;

			this.allClassifications = {};

			const classificationsObj = await classificationDAO.getClassificationsByDispatchId(this.row.item.id);
			_.forEach(classificationsObj, (classification) => {
				this.allClassifications[classification.assetType] = classification;
			});

			EventBus.$emit('onLoadAssetsClassification');

			// hide loading indicator
			this.isLoading = false;
		},
		getUserDisplay(userId) {
			return UserUtil.getUserDisplay(this.allUsersObj, userId);
		},
		showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		showDispatchLocation(details, endpoint) {
			const params = {
				source: details.source,
				destination: details.destination,
				endpoint,
			};

			EventBus.$emit('onUpdateDispatchLocationView', params);
			this.$bvModal.show('dispatch-location-view');
		},
		hasImage(media) {
			if (media) {
				let url = media.url ? media.url : '';
				return url && url.length > 0;
			}
			return false;
		},
		showImage(url) {
			let fileName = FileUtil.getUrlFileName(url);
			EventBus.$emit('onSelectImageView', {
				url: url,
				name: fileName
			});
			this.$bvModal.show('image-view-dialog');
		},
		getCoordinates(geoaddress) {
			return LocationUtil.getCoordinates(geoaddress);
		},
		hasClientAccount(dispatch) {
			return dispatch.clientAccountNo && dispatch.clientAccountNo.length > 0;
		}
	},

};
</script>

<style scoped>
.folder-tree {
	width: 14px;
	margin-bottom: 1px;
}
</style>
